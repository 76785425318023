import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { IQuerySales } from "../models/sales-sf.models";
import { IState } from "../reducers/reducers";
import { cancelOrderSF, generateOrderSFXlsx, listAllSales } from "../utils/webApi";
import { asyncSetSales, asyncSetTotal, SalesSFActionTypes, setIsGeneratingReport, setLoading } from "../reducers/sales-sf.reducer";
import handleError from "../utils/handleError";
import Notification, { NOTIFICATION_TYPE } from "../components/notification/Notification";
import { queryStringList } from "../utils/utils.functions";
import moment from "moment-timezone";

export function* getAllSalesSF({payload}: any) {
    try {
        yield put(setLoading(true));
        const {
            from,
            to,
            search,
            status,
        } = yield select((state: IState) => state.salesSF);

        const { total, data } = yield call(
            listAllSales,
            { page: payload.page, pageSize: payload.pageSize, from, to, search, status } as IQuerySales
        )

        yield put(asyncSetSales(data));
        yield put(asyncSetTotal(total));

        if (payload.callback) {
            payload.callback({ data, total });
        }

    } catch (error) {
        handleError(error);
    } finally {
        yield put(setLoading(false));
    }
}

function* cancelOrder({payload}: any) {
    try {
        yield put(setLoading(true));

        yield call(cancelOrderSF, payload.orderId);

        yield call(getAllSalesSF, {payload})

        Notification(NOTIFICATION_TYPE.SUCCESS, 'Sucesso', 'Ordem cancelada com sucesso!');
    } catch (error) {
        handleError(error);
    } finally {
        yield put(setLoading(false));
    }
}

function* generateReport() {
    try {
        yield put(setIsGeneratingReport(true));

        const {
            from,
            to,
            search,
            status,
        } = yield select((state: IState) => state.salesSF);

        const queryString = queryStringList({from, to, search, status})

        const base64: string  = yield call(generateOrderSFXlsx, queryString);

        const link = document.createElement('a');
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
        const timestamp = moment().format('YYYYMMDD_HHmmss');

        link.download = `Orders_SF_${timestamp}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (err) {
        handleError(err);
    }
    finally {
        yield put(setIsGeneratingReport(false));
    }
};

export default function* MySaga(): any {
    yield all([
        yield takeEvery(SalesSFActionTypes.ASYNC_GET_SALES_SF, getAllSalesSF),
        yield takeEvery(SalesSFActionTypes.ASYNC_CANCEL_ORDER, cancelOrder),
        yield takeEvery(SalesSFActionTypes.ASYNC_GENERATE_REPORT, generateReport),
    ]);
}
