/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import TitleSection from '../../components/title-section/TitleSection';
import { Icon } from 'antd';
import handleError from '../../utils/handleError';
import { listSales, updateSaleSF, resendSaleSF } from '../../utils/webApi';
import { routes } from '../../utils/routers.constants';
import { Link } from 'react-router-dom';
import useCheckPermissions from '../../hooks/useCheckPermissions';

import './SalesSF.scss';
import { history } from '../../config/Store.config';
import SalesList from './sales-list/SalesList';
import { ISale, ISaleDetail, ICustomer } from '../../models/sales-sf.models';
import SalesEdit from './sales-edit/SalesEdit';
import Notification, { NOTIFICATION_TYPE } from '../../components/notification/Notification';
import { asyncCancelOrder, asyncGetSalesSF } from '../../reducers/sales-sf.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../reducers/reducers';
import SearchFilter from './search-filter/SearchFilter';

const SalesSF = ({ match }: RouteComponentProps) => {
    const dispatch = useDispatch();
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [sales, setSales] = useState<ISale[]>([]);
    const isLoading = useSelector((state: IState) => state.salesSF.isLoading);
    const [idCoach, setIdCoach] = useState<string | undefined>((match.params as any).id);
    const [selectedSale, setSelectedSale] = useState<ISaleDetail>();
    const [editLoading, setEditLoading] = useState(false);
    const checkPermissions = useCheckPermissions();
    const { search, status, from, to } = useSelector(({ salesSF }: IState) => ({
        search: salesSF.search,
        status: salesSF.status,
        from: salesSF.from,
        to: salesSF.to,
    }));

    useEffect(() => {
        const params = match.params as { id?: string };
        setIdCoach(params.id);

    }, [match.params])

    useEffect(() => {
        const getAllSales = () => {
            dispatch(asyncGetSalesSF((response: { data: ISale[]; total: number }) => {
                setSales(response.data);
                setTotal(response.total);
            }, { page, pageSize, search, status }));
        };

        getAllSales();
    }, [page, pageSize, idCoach, dispatch]);

    const handleSearch = (search: string, status: string) => {
        dispatch(asyncGetSalesSF((response: { data: ISale[]; total: number}) => {
            setSales(response.data);
            setTotal(response.total);
        }, { page: 1, pageSize, search, status, from, to }));
    };

    const handleEditSale = async (sale: ISale) => {
        try {
            setEditLoading(true);
            setSelectedSale({ orderId: sale.orderId } as ISaleDetail);
            const response = await listSales(sale.orderId);
            setSelectedSale(response);
        } catch (error) {
            handleError(error);
        } finally {
            setEditLoading(false);
        }
    };

    function onChangeSaleEdit(key?: string, value?: any) {
        if (!selectedSale) return;

        const updatedSale = { ...selectedSale };
        let newKey = key ? key.split('.') : undefined;

        if (key) {
            if ((newKey && newKey.length) && newKey.length > 1) {
                updatedSale.customer = {
                    ...updatedSale.customer,
                    [newKey[0]]: {
                        ...(updatedSale.customer[newKey[0] as keyof ICustomer] as object),
                        [newKey[1]]: value,
                    }
                };
            } else {
                updatedSale.customer = { ...updatedSale.customer, [key]: value };
            }
        }

        setSelectedSale(updatedSale as ISaleDetail);
    }

    const onSaveSale = async function (orderId: string, resend?: boolean) {
        if (selectedSale) {
            try {
                setEditLoading(true);
                const sale = {
                    orderId: orderId,
                    customer: selectedSale.customer,

                };
                await updateSaleSF(orderId, {
                    ...sale,
                    customer: {
                        ...sale.customer,
                        corporateName: sale.customer.corporateName || undefined,
                        doingBusiness: sale.customer.doingBusiness || undefined,
                    }
                }
                );
                if (resend) {
                    await resendSaleSF(orderId);
                }
                setSelectedSale(undefined);
                Notification(NOTIFICATION_TYPE.SUCCESS, 'Sucesso', resend ? 'Reenviado com sucesso!!!' : 'Atualizado com sucesso!!!');
            } catch (error) {
                handleError(error);
            } finally {
                setEditLoading(false);
            }
        }
    }

    const handleCancelOrder = (orderId: string) => {
        dispatch(asyncCancelOrder((response: { data: ISale[]; total: number }) => {
            setSales(response.data);
            setTotal(response.total);
        }, { page, pageSize, search, status, orderId, from, to }));
    }

    return (
        <section>
            {
                idCoach &&
                <Link className="link-redirect-coaches" to={routes.COACHES} onClick={(e) => {
                    e.preventDefault();
                    history.goBack();
                }}>
                    <Icon style={{ marginRight: 10 }} type="arrow-left" />
                    Voltar
                </Link>
            }

            <TitleSection title="Vendas Sales Force" />
            <SearchFilter search={search} status={status} from={from} to={to} onSearch={handleSearch}/>
            <SalesList
                isLoading={isLoading}
                sales={sales}
                checkPermissions={checkPermissions}
                page={page}
                pageSize={pageSize}
                idCoach={idCoach}
                total={total}
                onChangePage={setPage}
                onChangePageSize={setPageSize}
                onEditSale={handleEditSale}
                handleCancelOrder={handleCancelOrder}
            />

            {selectedSale && (
                <SalesEdit
                    onOk={onSaveSale}
                    onChange={onChangeSaleEdit}
                    onClose={() => setSelectedSale(undefined)}
                    sale={selectedSale}
                    loading={isLoading}
                    editLoading={editLoading}
                />
            )}
        </section >
    )
};

export default SalesSF;
