import { ILicenseTypes } from "./utils.model";

export interface ISale {
    firstName: string,
    lastName: string,
    email: string,
    productCode: string,
    productType: string,
    orderId: string,
    paymentAmount: number,
    paymentId: string,
    status: string,
}

export interface ICustomer{
    email: string,
    phone: string,
    address: IAddress,
    document: IDocument,
    lastName: string,
    firstName: string,
    corporateName: string,
    doingBusiness: string,
}

export interface IDocument {
    type: string,
    number: string,
}

export interface IAddress {
    city: string,
    state: string,
    number: string,
    street: string,
    country: string,
    district: string,
    postalCode: string,
}

export interface IPaymentSummary {
    amount: number,
    taxAmount: number,
    paymentType: string,
    installments: number,
}

export interface ISaleDetail {
    orderId: string,
    paymentId: string,
    status: string,
    customer: ICustomer,
    productCode: string,
    productType: string,
    paymentSummary: IPaymentSummary,
}

export interface ISaleUpdate {
    orderId: string,
    customer: ICustomer,
}

export interface ILicence {
    id: string,
    created: string,
    name: string
    dataAtivacao: string,
    dataExpiracao: string,
    dataAtivacaoGlobal: string,
    dataExpiracaoGlobal: string,
    tipoLicenca: ILicenseTypes,
    duracao: number,
    duracaoGlobal: number,
    valid: boolean,
    recorrenteWarning: boolean
}

export interface IQuerySales {
    page: number;
    pageSize: number;
    search?: string | null;
    from?: string | null;
    to?: string | null;
    status?: string | null;
}

export interface IListSales{
    data: ISale[],
    total: number,
}

export enum SalesTypes {
    ANUAL = 'Anual',
    RECORRENTE = 'Recorrente',
};

export enum SaleStatus {
    PENDING = 'PENDING',
    CREATED = 'CREATED',
    CANCELED = 'CANCELED',
    ALL = 'ALL',
};

export interface IQueryStringList {
    page?: number,
    pageSize?: number,
    from?: string,
    to?: string,
    search?: string,
    status?: string,
}
