import { ISale, ISaleDetail } from "../models/sales-sf.models"

// Types

export enum SalesSFActionTypes {
    ASYNC_GET_SALES_SF = '@sales-sf/ASYNC_GET_SALES_SF',
    ASYNC_GET_SALE = '@sales-sf/ASYNC_GET_SALE',
    ASYNC_SET_SALES_SF = '@sales-sf/ASYNC_SET_SALES_SF',
    ASYNC_SET_TOTAL = '@sales-sf/ASYNC_SET_TOTAL',
    SET_LOADING = '@sales-sf/SET_LOADING',
    ASYNC_SET_SALE = '@sales-sf/ASYNC_SET_SALE',
    CHANGE_SEARCH_VALUE = '@sales-sf/CHANGE_SEARCH_VALUE',
    CHANGE_STATUS_VALUE = '@sales-sf/CHANGE_STATUS_VALUE',
    CHANGE_FROM_DATE = '@sales-sf/CHANGE_FROM_DATE',
    CHANGE_TO_DATE = '@sales-sf/CHANGE_TO_DATE',
    ASYNC_CANCEL_ORDER = '@sales-sf/ASYNC_CANCEL_ORDER',
    SET_IS_GENERATING_REPORT = '@sales-sf/SET_IS_GENERATING_REPORT',
    ASYNC_GENERATE_REPORT = '@sales-sf/ASYNC_GENERATE_REPORT',
}


// Interfaces

export interface ISalesSFStore {
    sales: ISale[],
    sale: ISaleDetail[],
    search: string,
    page: number,
    pageSize: number,
    from: string,
    to: string,
    total: number,
    status: string,
    isLoading: boolean,
    isModalLoading: boolean,
    currentSalesDetails: ISaleDetail[],
    isOpenEditSale: boolean,
    isGeneratingReport: boolean,
}

export interface SalesSFAction {
    type: SalesSFActionTypes,
    payload: any
};


// Reducer

const INITIAL_STATE: ISalesSFStore = {
    sales: [],
    sale: [],
    search: '',
    page: 1,
    pageSize: 10,
    from: '',
    to: '',
    status: '',
    total: 0,
    isLoading: false,
    isModalLoading: false,
    currentSalesDetails: [],
    isOpenEditSale: false,
    isGeneratingReport: false,
}

export const reducer = (
    state: ISalesSFStore = INITIAL_STATE,
    action: SalesSFAction
): ISalesSFStore => {
    const {type, payload} = action;

    switch (type) {
        case SalesSFActionTypes.ASYNC_GET_SALES_SF:
            return {...state, isLoading: true, page: payload.page || state.page, pageSize: payload.pageSize || state.pageSize};
        case SalesSFActionTypes.ASYNC_SET_TOTAL:
            return {...state, total: payload};
        case SalesSFActionTypes.SET_LOADING:
            return {...state, isLoading: payload};
        case SalesSFActionTypes.ASYNC_SET_SALES_SF:
            return {...state, sales: payload, isLoading: false};
        case SalesSFActionTypes.CHANGE_SEARCH_VALUE:
            return {...state, search: payload};
        case SalesSFActionTypes.CHANGE_STATUS_VALUE:
            return {...state, status: payload};
        case SalesSFActionTypes.CHANGE_FROM_DATE:
            return {...state, from: payload};
        case SalesSFActionTypes.CHANGE_TO_DATE:
            return {...state, to: payload};
        case SalesSFActionTypes.SET_IS_GENERATING_REPORT:
            return {...state, isGeneratingReport: payload};
        default:
            return state;
    }
}


// Actions

export const asyncGetSalesSF = (callback?: (sales: { data: ISale[]; total: number }) => void, { page, pageSize, from, to, search, status }: any = {}) => ({
    type: SalesSFActionTypes.ASYNC_GET_SALES_SF,
    payload: { callback, page, pageSize, status },
});

export const asyncGetSale = (callback?: (sale: ISaleDetail[]) => void) => ({
    type: SalesSFActionTypes.ASYNC_GET_SALE,
    payload: { callback },
});

export const asyncSetSales = (payload: ISale[]) => ({
    type: SalesSFActionTypes.ASYNC_SET_SALES_SF,
    payload,
});

export const asyncSetTotal = (payload: number) => ({
    type: SalesSFActionTypes.ASYNC_SET_TOTAL,
    payload,
});

export const setLoading = (payload: boolean) => ({
    type: SalesSFActionTypes.SET_LOADING,
    payload,
});

export const changeSearchValue = (payload: string) => ({
    type: SalesSFActionTypes.CHANGE_SEARCH_VALUE,
    payload,
});

export const changeStatusValue = (payload: string) => ({
    type: SalesSFActionTypes.CHANGE_STATUS_VALUE,
    payload,
});

export const changeFromDate = (payload: string) => ({
    type: SalesSFActionTypes.CHANGE_FROM_DATE,
    payload,
});

export const changeToDate = (payload: string) => ({
    type: SalesSFActionTypes.CHANGE_TO_DATE,
    payload,
})

export const asyncCancelOrder = (callback?: (sales: { data: ISale[]; total: number }) => void, { orderId, page, pageSize, from, to, search, status }: any = {}) => ({
    type: SalesSFActionTypes.ASYNC_CANCEL_ORDER,
    payload: { orderId, callback, page, pageSize, status },
})

export const asyncGenerateReport = () => ({
    type: SalesSFActionTypes.ASYNC_GENERATE_REPORT,
})

export const setIsGeneratingReport = (payload: boolean) => ({
    type: SalesSFActionTypes.SET_IS_GENERATING_REPORT,
    payload,
})

export default reducer;
